.sobre {
  width: 100%;
  height: 50rem;
  background-color: #1B1464;

  .titleSobre {
    color: white;
    font-weight: 500;
    font-size: 4rem;
    margin-bottom: 3rem;
    font-family: cursive;
    text-align: center;
    padding-top: 10rem;
  }

  .textSobre {
    color: white;
    width: 40%;
    font-size: 1.5rem;
    margin: auto;
    text-align: center;
  }
}

.frame {
  margin-top: 2rem;
  margin-bottom: 1.8rem;
  text-align: center;
}

.frame3,
.frame4 {
  display: none;
}

@media (max-width: 768px) {

  .frame1,
  .frame2 {
    display: none;
  }

  .frame3,
  .frame4 {
    display: flex !important;
  }

  .textSobre {
    background-color: white !important;
    color: #1B1464 !important;
    width: 80% !important;
  }
}