.cadastroContainer {
  display: flex;
  background-color: #f0f2f5;
  height: 100%;
}

.cadastroBox {
  background-color: #fff;
  padding: 0 40px;
  box-shadow: 1px 4px 8px rgba(0, 0, 0, 0.3);
  width: 80%;
  max-width: 400px;
  margin-left: auto;
  height: 100%;
  overflow: auto;
}

.cadastroButton:hover,
.voltarButton:hover,
.filterButton:hover {
  background-color: #746EB8;
}

.filterButton {
  background-color: #58538B;
  border: none;
  border-radius: 4px;
  padding: 5px 15px;
  font-size: 14px;
  cursor: pointer;
  font-weight: 600;
  word-spacing: 10px !important;
  font-size: 1rem;
  transition: background-color 0.2s ease-in-out;
  color: #fff;
  text-decoration: none;
  margin-left: auto;
  letter-spacing: .5px;
}

.cadastroButton {
  background-color: #58538B;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 15px;
  font-size: 14px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  transition: background-color 0.2s ease-in-out;
  width: 100%;
}


.voltarButton {
  background-color: #58538B;
  border: none;
  border-radius: 4px;
  padding: 10px 15px;
  margin-top: .5rem;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  width: 100%;
  color: #fff;
  text-decoration: none;
}

.modal {
  background-color: white;
  border-radius: 8px;
  padding: 1rem;
  width: 30%;
  margin: auto;
  box-shadow: 0 0 .4rem .1rem rgba(0, 0, 0, 0.1);
  margin-top: 10rem;
  min-width: 20rem;
}

.modal h2 {
  font-family: cursive;
  font-size: 1.2rem;
  color: #58538B;
  margin-top: .3rem !important;
}

.contentButtonsModal {
  text-align: center;
  width: 100%;
}

.modal button {
  margin-top: 1rem;
  padding: .5rem 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  color: white;
  background-color: #58538B;
}

.modal button:hover {
  opacity: 0.9;
}

.visualizarBox {
  background-color: #f0f2f5;
  padding: 0 40px;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  overflow: auto;

  .contentTable {
    background-color: rgb(250, 247, 247);
    margin-top: .5rem;
  }

  .titleVisualizar {
    font-size: 1.8rem;
    font-weight: 500;
  }
}

.errorText {
  color: red;
  font-size: 12px;
  display: flex;
  margin-top: -5px;
  margin-bottom: 1.5px;
  margin-left: 1.3px;
}


h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  text-align: center;
}

.formGroup {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  width: 94.3% !important;
}

.toggle {
  font-size: 2rem;
  cursor: pointer;
}

.cadastroButton {
  background-color: #58538B;
  ;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 15px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  width: 100%;
  margin-top: 1rem;
}

.cadastroButton:hover,
.voltarButton:hover {
  background-color: #746EB8;
  ;
}

.voltarButton {
  background-color: #58538B;
  ;
  border: none;
  border-radius: 4px;
  padding: 10px 15px;
  margin-top: .5rem;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  width: 100%;
  color: #fff;
  text-decoration: none;
}

.errorTextFilter {
  color: red;
  font-size: 16px;
  display: flex;
  margin-top: -5px;
  margin-bottom: 1.5px;
  margin-left: 1.3px;
}


.modal button:hover {
  opacity: 0.9;
}

.header {
  display: flex;
  align-items: center;
}

.iconFilter {
  margin-left: auto;
}

@media (max-width: 768px) {
  .cadastroBox {
    width: 90%;
    max-width: none;
  }
}