@import 'primeicons/primeicons.css';
@import 'primereact/resources/themes/lara-light-indigo/theme.css';
@import 'primereact/resources/primereact.min.css';
@import 'primeflex/primeflex.css';
@import 'primereact/resources/primereact.css';

html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}

#root { 
  height: 100vh;
}