.registerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f2f5;
}

.registerBox {
  background-color: #fff;
  padding: 0 20px 20px 20px;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  width: 80%;
  max-width: 400px;
}

.modal {
  background-color: white;
  border-radius: 8px;
  padding: 1rem;
  width: 30%;
  margin: auto;
  box-shadow: 0 0 .4rem .1rem rgba(0, 0, 0, 0.1);
  margin-top: 3rem;
  min-width: 20rem;
}

.modal h2 {
  font-family: cursive;
  font-size: 1.2rem;
  color: #58538B;
  margin-top: .3rem !important;
}

.contentButtonsModal {
  text-align: center;
  width: 100%;
}

.modal button {
  margin-top: 1rem;
  padding: .5rem 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  color: white;
  background-color: #58538B;
}

.modal button:hover {
  opacity: 0.9;
}

h2 {
  margin-top: 2rem !important;
}

.formGroup {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.errorText {
  color: red;
  font-size: 12px;
  display: flex;
  margin-top: -5px;
  margin-bottom: 1.5px;
  margin-left: 1.3px;
}

input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  width: 94.3% !important;
}

.registerButton {
  background-color: #58538B;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 15px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  width: 100%
}

.registerButton:hover,
.voltarButton:hover {
  background-color: #746EB8;
}

.voltarButton {
  background-color: #58538B;
  border: none;
  border-radius: 4px;
  padding: 10px 15px;
  margin-top: .5rem;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  width: 100%;
  color: #fff;
  text-decoration: none;
}

.cadastroSucesso {
  color: green;
  border: 2px solid green;
  border-radius: .4rem;
  padding: .5rem;
}

@media (max-width: 768px) {
  .registerBox {
    width: 90%;
    max-width: none;
  }
}