.table {
  border-collapse: collapse;
  width: 100%;
}

.th,
.td {
  border: 1px solid #ddd;
  padding: 4px;
  text-align: left;
}

.th {
  background-color: #f2f2f2;
}

.tr:nth-child(even) {
  background-color: #f2f2f2;
}

.buttonEdit {
  margin-left: .5rem;
}

.tr:hover {
  background-color: #ddd;
}