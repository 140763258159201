.footer {
  background-color: #DC2244;
  color: #fff;
  padding: 20px;
  display: flex;
}

.containerContato {
  text-align: left;
  margin-left: 10rem;
}

.containerRedesSociais {
  margin-left: auto;
  margin-right: 15rem;
}

.title {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.text {
  margin: 0;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

li {
  margin-bottom: .5rem;
}

.redesSociais {
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.textIcon {
  margin-left: .5rem;
  margin-bottom: .1rem;
  align-items: center;
}

@media (max-width: 768px) {

  .footer {
    background-color: #DC2244;
    color: #fff;
    padding: 1rem;
    display: flex;
  }
  
  .containerContato {
    text-align: left;
    margin-left: 1rem;
    font-size: .9rem;
  }

  .containerRedesSociais {
    margin-left: auto;
    margin-right: 0;
    font-size: .9rem;
  }

  .title {
    font-size: 1rem;
    margin-bottom: 10px;
  }

}