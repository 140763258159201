.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 8px;
  margin: auto;
}

.datePicker {
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  font-size: 16px;

  select {
    padding: .3rem .5rem;
    margin-top: .5rem;
  }
}

.react-datepicker__current-month {
  font-size: 1.5em;
  font-weight: bold;
  color: #ff5733;
}

div {
  float: none !important;
}

.dateContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.date {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  font-size: 16px;
  margin-bottom: 10px;
}